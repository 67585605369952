// extracted by mini-css-extract-plugin
export var root = "Input-module--root--2U0kK";
export var wrapper = "Input-module--wrapper--2AeZl";
export var input = "Input-module--input--1Y-87";
export var label = "Input-module--label--1YOol";
export var errorContainer = "Input-module--errorContainer--1eG_Z";
export var errorText = "Input-module--errorText--3y83Z";
export var focused = "Input-module--focused--3IhOO";
export var error = "Input-module--error--2X-v6";
export var light = "Input-module--light--1CJ_7";
export var dark = "Input-module--dark--13MSL";