import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';

import * as s from './Input.module.sass';
import { ColorModes, ColorThemeContext } from '@providers/ColorThemeContext';

type InputProps = {
  label?: string
  error?: string
  inputClassName?: string
} & (React.InputHTMLAttributes<HTMLInputElement>);

const modeClass = {
  [ColorModes.Light]: s.light,
  [ColorModes.Dark]: s.dark,
};

export const Input: React.FC<InputProps> = ({
  label,
  error = '',
  className,
  inputClassName,
  value,
  disabled,
  onFocus,
  onBlur,
  id,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(!!(value && value !== ''));
  const { colorThemeMode } = useContext(ColorThemeContext);

  useEffect(() => {
    setIsFocused(!!(value && value !== ''));
  }, [value]);

  const compoundClassName = cx(
    s.root,
    modeClass[colorThemeMode],
    { [s.focused]: isFocused },
    { [s.error]: error },
    className,
  );

  return (
    <div className={compoundClassName}>
      <div
        className={s.wrapper}
      >
        <input
          {...props}
          className={cx(s.input, inputClassName)}
          onFocus={(e) => {
            setIsFocused(true);
            if (onFocus) {
              onFocus(e);
            }
          }}
          onBlur={(e) => {
            if (value === '') {
              setIsFocused(false);
            }
            if (onBlur) {
              onBlur(e);
            }
          }}
          disabled={disabled}
          value={value}
          id={id}
        />
        {label && <label htmlFor={id} className={s.label}>{label}</label>}
      </div>
      <div className={s.errorContainer}>
        {error && <div className={s.errorText}>{error}</div>}
      </div>
    </div>
  );
};
