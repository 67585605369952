import * as React from 'react';
import { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

type LinkArrowProps = {
  isRotate?: boolean
  isBold?: boolean
  delay?: number
  className?: string
};

export const LinkArrow: React.FC<LinkArrowProps> = ({
  isRotate = false,
  isBold = false,
  delay = .3,
  className,
}) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({
    threshold: .5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start({
        x: 0,
        y: 0,
        opacity: 1,
        scale: 1,
        rotate: isRotate ? 30 : 0,
        transition: {
          duration: .4,
          delay: delay,
          ease: 'easeIn',
        },
      });
    }
  }, [controls, delay, inView, isRotate]);

  if (isBold) {
    return (
      <span
        ref={ref}
        className={className}
      >
        <motion.svg
          initial={{
            x: -14,
            y: 14,
            opacity: 0,
            scale: .2,
            rotate: 0,
          }}
          animate={controls}
          width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <path fillRule="evenodd" clipRule="evenodd" d="M42.9922 14.0248V14L14.9923 13.9999L14.9922 19L34.4565 19.0001L12.233 41.2236L15.7685 44.7591L38.0169 22.5108L38.017 42.0248L43.0171 42.0247L43.017 14.0248H42.9922Z" fill="#1F1F1F"/>
        </motion.svg>
      </span>
    );
  }

  return (
    <span
      ref={ref}
      className={className}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <motion.svg
        initial={{
          x: -14,
          y: 14,
          opacity: 0,
          scale: .2,
          rotate: 0,
        }}
        animate={controls}
        width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg"
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M24.7145 7.6626L24.7145 7.66299L24.7173 7.6626V21.6307L21.7173 21.6307V12.7839L8.10028 26.4009L5.97896 24.2796L19.5987 10.6598H10.7464L10.7464 7.65984H24.7145L24.7145 7.6626Z" fill="#1F1F1F"/>
      </motion.svg>
    </span>
  );
};